.dashboard-backdrop {
    background: url("../../../assets/img/backdrop.jpg");
    min-height: 15rem;
    background-size: cover;
    background-position: center;
    box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 1%), 0 6.7px 5.3px rgb(0 0 0 / 3%), 0 12.5px 10px rgb(0 0 0 / 1%), 0 22.3px 17.9px rgb(0 0 0 / 1%), 0 41.8px 33.4px rgb(0 0 0 / 2%), 0 100px 80px rgb(0 0 0 / 1%);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1.1rem;

    > .dashboard-backdrop-fill {
        background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9) 80%);
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding: 2.25rem 2.5rem;
        height: 100%;
        width: 100%;

        > .inner {
            color: #fff;
            text-shadow: 0 2px 5px #000;
            font-size: 48px;
        }
    }
}
