@import "~@financeone/core-ui/build/vars.scss";

$brightnessOffset: -20%;

.code-block {
    padding: 0.5rem;
    background: var(--rc-background-800);
    border-radius: 8px;

    .linenumber {
        color: hsl(194, 61%, 43% + $brightnessOffset);
    }

    .hljs {
        &-name {
            color: hsl(355, 82%, 55% + $brightnessOffset);
        }

        &-literal,
        &-keyword {
            color: hsl(240, 90%, 90% + $brightnessOffset);
        }

        &-attr {
            color: hsl(5, 100%, 75% + $brightnessOffset);
        }

        &-string {
            color: hsl(18, 50%, 68% + $brightnessOffset);
        }

        &-number {
            color: hsl(99, 28%, 73% + $brightnessOffset);
        }

        &-function {
            color: hsl(168, 53%, 55% + $brightnessOffset);
        }

        &-params {
            color: hsl(0, 0%, 86% + $brightnessOffset);
        }
    }

    &:not(:first-child) {
        margin-top: 1rem;
    }

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}
